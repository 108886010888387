import React from 'react';
import { Link } from 'react-router-dom';
import { PoseGroup } from 'react-pose';
import ModelTable, {Property} from '../../../utils/model_table';
import './contract_list.scss';
import {getModel, getModels, postModel, deleteModel, getAsLocalDate, getCurrencyText, getLocalDateIsoString} from '../../../utils/functions';
import * as routes from '../../../constants';
import * as paths from '../constants';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE, CONTRACT_EXPIRATION_ALERT_RANGE, DEFAULT_UNIT_TYPE} from '../../../constants';
import ConfirmationWindow from '../../confirmation_window';
import {FadeContainer} from '../../../utils/pose_containers';
import PreLoader from '../../../utils/preloader';
import * as permissions from '../../../permissions';


class ContractList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contracts: [],
      loadingData: true,
      deleteId: null,
      cancelId: null,
      sendEmail: false,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      screenWidth: window.innerWidth,
      clipboardWritePermission: false,
      userFromLinkCopied: false,
      registrationEmailSent: false
    };
  }

  isDefaultUnit() {
    return this.props.unit_type_id === DEFAULT_UNIT_TYPE;
  }

  async getContracs() {
    return await getModels(`${routes.CONTRACTS_GET_API}?student_id=${this.props.student.id}`);
  }

  async componentDidMount() {
    this.reloadList();

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);

    const permissionStatus = await navigator.permissions.query({name: 'clipboard-write'});

    if(permissionStatus.state === 'granted') {
      this.setState({
        clipboardWritePermission: true
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async reloadList() {
    this.setState({
      loadingData: true
    });

    const contracts = await this.getContracs();

    this.props.onUpdateContracts(contracts);

    this.setState({
      loadingData: false,
      contracts: contracts
    });
  }

  onSendRegistrationEmail() {
    this.setState({
      sendEmail: true,
      deleteId: null,
      cancelId: null,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onDeleteEntry(entryId) {
    this.setState({
      deleteId: entryId,
      cancelId: null,
      sendEmail: false,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onCancelEntry(entryId) {
    this.setState({
      cancelId: entryId,
      deleteId: null,
      sendEmail: false,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onCancelConfirmation() {
    this.setState({
      deleteId: null,
      cancelId: null,
      sendEmail: false,
    });
  }

  async onConfirm() {
    this.setState({
      confirmInProgress: true
    });

    if(this.state.cancelId) {
      try {
        if(await postModel(routes.CONTRACT_CANCEL_API.replace('{id}', this.state.cancelId.toString()))) {
          this.reloadList();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else if(this.state.deleteId) {
      try{
        if(await deleteModel(`${routes.CONTRACT_DELETE_API}${this.state.deleteId}`)) {
          this.reloadList();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else if(this.state.sendEmail) {
      try {
        if(await postModel(`${routes.NOTIFICATION_REGISTRATION_LINK_POST}?already_registered=${this.props.student.disc !== null}`, {user_ids: [this.props.student.id]})) {
          this.setState({
            registrationEmailSent: true
          });
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false,
          registrationEmailSent: false
        });

        return;
      }
    }

    this.setState({
      deleteId: null,
      cancelId: null,
      sendEmail: false,
    });
  }

  getStatusText(entry) {
    const status = entry.status.toLowerCase().trim();

    if(status.startsWith('ativo')) {
      return (
        <p className="contract-list__status-text__ativo">{entry.status}</p>
      );
    }
    else if(status.startsWith('vencido')) {
      return (
        <p className="contract-list__status-text__vencido">{entry.status}</p>
      );
    }
    else if(status.startsWith('cancelado')) {
      return (
        <p className="contract-list__status-text__cancelado">{entry.status}</p>
      );
    }
    else if(status.startsWith('aceito')) {
      return (
        <p className="contract-list__status-text__aceito">{entry.status}</p>
      );
    }

    return (
      <p className="contract-list__status-text__inativo">{entry.status}</p>
    );
  }

  getServiceText(entry) {
    return entry.service_plan.name;
  }

  getExpiresAtText(entry) {
    const today = new Date();
    const todayString = getLocalDateIsoString(today);
    const date = getAsLocalDate(entry.expires_at);
    const dateString = getLocalDateIsoString(date);

    return (
      <div className="contract-list__cell-wrapper">

        <p className={`contract-list__date-text${dateString < todayString ? '--past' : entry.days_to_expire < CONTRACT_EXPIRATION_ALERT_RANGE ? '--alert' : ''}`}>

          {date.toLocaleDateString()}

        </p>

      </div>
    );
  }

  getProperties() {
    let properties = [
      Property('service_name', 'Serviço', <i className="fas fa-concierge-bell"></i>, {getDataText: this.getServiceText, getFilterText: this.getServiceText, cellClassName: "contract-list__service-cell"}),
    ];

    if(this.state.screenWidth > 660) {
      properties.push(
        Property('total_value', 'Valor', <i className="fas fa-money-bill-alt"></i>, {getDataText: (entry) => getCurrencyText(entry.total_value)}),
      );
    }

    if(this.state.screenWidth > 550) {
      properties.push(
        Property('expires_at', 'Expiração', <i className="fas fa-calendar-alt"></i>, {getDataText: this.getExpiresAtText, getFilterText: this.getExpiresAtText}),
      );
    }

    properties = [
      ...properties,
      Property('status', 'Situação', <i className="fas fa-thermometer-half"></i>, {getDataText: this.getStatusText}),
    ];

    return properties;
  }

  listHasActions() {
    return this.props.userPermissionIds.includes(permissions.EDIT_CONTRACT_PERMISSION_ID) ||
           this.props.userPermissionIds.includes(permissions.DELETE_CONTRACT_PERMISSION_ID) ||
           this.props.userPermissionIds.includes(permissions.DOWNLOAD_STUDENT_ACCEPTED_CONTRACT_PERMISSION_ID);
  }

  getActions(entry) {
    return (
      <div className="model-table__model-actions-container contract-list__action-container">

        {this.props.userPermissionIds.includes(permissions.EDIT_CONTRACT_PERMISSION_ID) &&
          <Link
            className="model-table__default-edit-button"
            to={`${routes.STUDENT_EDIT_PATH}${this.props.student.id}${paths.CONTRACT_EDIT_PATH}${entry.id}`}
          >

              <i className="fas fa-edit"></i>

          </Link>
        }

        {(this.props.userPermissionIds.includes(permissions.DOWNLOAD_STUDENT_ACCEPTED_CONTRACT_PERMISSION_ID) && entry.contract_pdf_url) && (
          <a
            className="model-table__default-link-button"
            href={entry.contract_pdf_url}
            target="_blank"
            rel="noopener noreferrer"
            download={`${(this.props.student.name + '_' + entry.accepted_at.slice(0, 10)).replaceAll(/[/\s]/g, '_')}.pdf`}
          >

            <i className="fa-solid fa-file-arrow-down"></i>

          </a>)
        }

        {(this.props.userPermissionIds.includes(permissions.DELETE_CONTRACT_PERMISSION_ID) && !entry.canceled) && (!entry.accepted ?
          <button
            className="model-table__default-delete-button"
            onClick={() => this.onDeleteEntry(entry.id)}
          >

            <i className="far fa-trash-alt contract-list__action-icon"></i>
            Deletar

          </button>:
          <button
            className="model-table__default-delete-button"
            onClick={() => this.onCancelEntry(entry.id)}
          >

            <i className="fas fa-times contract-list__action-icon"></i>
            Cancelar

          </button>)
        }

      </div>
    );
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmInProgress) {
      if(this.state.cancelId) {
        return 'Cancelando contrato';
      }
      else if(this.state.sendEmail) {
        return 'Enviando email';
      }

      return 'Deletando contrato';
    }
    else if(this.state.confirmFailed) {
      if(this.state.cancelId) {
        return 'Falha ao cancelar contrato';
      }
      else if(this.state.sendEmail) {
        return 'Falha ao enviar email';
      }

      return 'Falha ao deletar';
    }
    else if(this.state.cancelId) {
      return 'Cancelar contrato';
    }
    else if(this.state.sendEmail) {
      return 'Enviar email de cadastro';
    }

    return 'Deletar contrato';
  }

  getConfirmationWindowDescription() {
    if(this.state.confirmFailed) {
      return this.state.confirmFailDescription;
    }
    else if(this.state.cancelId) {
      return 'O contrato será cancelado juntamente com todos seus pagamentos pendentes vinculados';
    }
    else if(this.state.sendEmail) {
      return 'Enviaremos um email ao aluno contendo o link de cadastro';
    }

    return 'Todos os dados relacionados ao contrato serão apagados';
  }

  getConfirmartionWindowConfirmText() {
    if(this.state.cancelId) {
      return 'Cancelar contrato';
    }
    else if(this.state.sendEmail) {
      return 'Enviar email';
    }

    return 'Deletar contrato';
  }

  async copyUserFormLink() {
    const userFormLink = `${window.location.protocol}//${window.location.host.replace('admin.', '').replace(':3000', ':5000')}${routes.STUDENT_FORM_PATH}${this.props.student.auth_hash}`;

    const message = `Olá, ${this.props.student.name}\n\n` +
                    'Por favor, utilize o link abaixo para confirmar seus dados cadastrais.\n\n' +
                    `Link de cadastro: ${userFormLink}\n\n` +
                    'Este procedimento é bem rápido e não levará mais que um minuto.\n\n' +
                    'Após confirmar que seus dados estão corretos, realizando as modificações se necessárias, NÃO SE ESQUEÇA DE CLICAR NO BOTÃO ACEITAR de seus CONTRATOS pendentes para completar o procedimento.\n\n' +
                    'Qualquer dúvida, não hesite em nos perguntar!';

    try {
      await navigator.clipboard.writeText(message);
      this.setState({userFromLinkCopied: true});

      window.setTimeout(() => {
        this.setState({userFromLinkCopied: false});
      }, 1000);
    }
    catch(error) {
      window.alert(error);
    }
  }

  getRegistrationLinkButton() {
    const userFormLink = `${window.location.protocol}//${window.location.host.replace('admin.', '').replace(':3000', ':5000')}${routes.STUDENT_FORM_PATH}${this.props.student.auth_hash}`;

    if(this.props.student.auth_hash) {
      if(this.state.clipboardWritePermission) {
        return (
          <button
            className="model-table__default-button"
            disabled={this.state.userFromLinkCopied}
            onClick={(event) => this.copyUserFormLink()}
          >

            <i className="fas fa-link"></i> {this.state.userFromLinkCopied ? 'Link copiado' : 'Copiar link do formulário'}

          </button>
        );
      }

      return (
        <a
          className="model-table__default-button"
          href={userFormLink}
          target="_blank"
          rel="noopener noreferrer"
        >

          <i className="fas fa-link"></i> Formulário de cadastro

        </a>
      );
    }

    return null;
  }

  async onOpenCustomerExitSurveyPage() {
    this.setState({
      loadingData: true
    });

    let surveyPath = await getModel(routes.STUDENT_EXIT_SURVEY_PATH_GET_API.replace('{student_id}', this.props.student.id));

    const path = `${window.location.protocol}//${window.location.host.replace('admin.', '').replace(':3000', ':5000')}${surveyPath}`;

    window.open(path, '_blank');

    this.setState({
      loadingData: false
    });
  }

  render() {
    return this.state.loadingData ? (
      <PoseGroup>
        <FadeContainer className="content-frame__loading-container" key="preloader">
          <PreLoader local={true} />
        </FadeContainer>
      </PoseGroup>
    ):
    (
      <React.Fragment>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.getConfirmationWindowDescription()}
          confirmText={this.getConfirmartionWindowConfirmText()}
          cancelText={this.state.confirmFailed ? 'Ok' : 'Cancelar'}
          visible={this.state.deleteId !== null || this.state.cancelId !== null || this.state.sendEmail}
          onCancel={() => this.onCancelConfirmation()}
          onConfirm={() => this.onConfirm()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={this.state.confirmFailed}
        />

        <ModelTable
          properties={this.getProperties()}
          data={this.state.contracts}
          initialOrderBy="expires_at"
          getActions={this.listHasActions() ? (entry) => this.getActions(entry) : null}
          initialOrderIsDecrescent={true}
        >

          {(this.isDefaultUnit() && this.state.contracts.some((entry) => {
            const status = entry.status.toLowerCase().trim();

            return status.startsWith('ativo') || status.startsWith('aceito');
          })) &&
            <button
              className="default-section__default-link-button"
              onClick={() => this.onOpenCustomerExitSurveyPage()}
            >

                <i className="fa-solid fa-rectangle-list default-section__default-link-button__icon"></i> Pesquisa de cancelamento

            </button>
          }

          {(this.props.userPermissionIds.includes(permissions.SEND_STUDENT_REGISTRATION_NOTIFICATION_PERMISSION_ID) && this.props.student.email_is_validated !== false && !this.props.student.block_email_notification) ?
            <button
              className="model-table__default-button"
              disabled={this.state.registrationEmailSent}
              onClick={(event) => this.onSendRegistrationEmail()}
            >

              <i className="fas fa-envelope"></i> {this.state.registrationEmailSent ? 'Email enviado' : 'Enviar email de cadastro'}

            </button>:
            null
          }

          {this.getRegistrationLinkButton()}

          {this.props.userPermissionIds.includes(permissions.ADD_CONTRACT_BASIC_PERMISSION_ID) &&
            <Link
              className="model-table__default-button"
              to={{
                pathname: `${routes.STUDENT_EDIT_PATH}${this.props.student.id}${paths.CONTRACT_ADD_PATH}`,
                state: { lastPath: this.props.location.pathname }
              }}
            >

              <i className="fas fa-plus"></i> Adicionar contrato

            </Link>
          }

        </ModelTable>

      </React.Fragment>

    );
  }
}

export default ContractList;
