import React from 'react';
import DefaultSection, {HorizontalRule} from '../../utils/default_section';
import DefaultInput, {SelectOption, HalfWrapper} from '../../utils/default_input';
import WarningMessage from '../warning_message';
import './work_presence_event_data.scss';


class WorkPresenceData extends React.Component {
  handleKeyDown(event) {
    if(event.keyCode === 13 && this.props.enableSave) {
      this.props.onSave();
    }
  }

  isHighlighted(propertyName) {
    return this.props.highlights.includes(propertyName);
  }

  isEditMode() {
    return this.props.work_presence_event.id && this.props.work_presence_event.id > 0;
  }

  getStaffOptions() {
    let options = this.props.users.filter((user) => user.is_active || user.id === this.props.work_presence_event.user_id).map((entry) => SelectOption(entry.id, entry.name));

    if(this.props.work_presence_event.user_id && this.props.work_presence_event.user_id > 0) {
      return [
        ...options
      ];
    }

    return [
      SelectOption('', 'Selecione um colaborador'),
      ...options
    ];
  }

  render() {
    return (
      <DefaultSection
        className="work-presence-data"
        title="Dados do período de atendimento"
      >

        <div className="work-presence-data__warning-container">

          <WarningMessage
            message={this.props.warningMessage}
            onClose={this.props.onCloseWarning}
            visible={this.props.showWarningMessage}
          />

        </div>

        <div className="work-presence-data__input-container">

          <DefaultInput
            name="user_id"
            isHighlighted={this.isHighlighted("user_id")}
            label="Colaborador"
            type="select"
            handleInputChange={this.props.handleInputChange}
            value={this.props.work_presence_event.user_id || ''}
            options={this.getStaffOptions()}
            disabled={this.isEditMode()}
          />

          <HalfWrapper>

            <DefaultInput
              name="entered_at"
              isHighlighted={this.isHighlighted("entered_at")}
              label="Hora da entrada"
              type="datetime-local"
              placeholder="Entrada"
              handleInputChange={this.props.handleInputChange}
              value={this.props.work_presence_event.entered_at}
              max={this.props.work_presence_event.end_at}
              autoComplete="off"
              onKeyDown={(event) => this.handleKeyDown(event)}
            />

            <DefaultInput
              name="exited_at"
              isHighlighted={this.isHighlighted("exited_at")}
              label="Hora da saída"
              type="datetime-local"
              placeholder="Saída"
              handleInputChange={this.props.handleInputChange}
              value={this.props.work_presence_event.exited_at}
              min={this.props.work_presence_event.start_at}
              autoComplete="off"
              onKeyDown={(event) => this.handleKeyDown(event)}
            />

          </HalfWrapper>

        </div>

        <HorizontalRule />

        <div className="work-presence-data__buttons-container">

          <button
            className="work-presence-data__save-button"
            disabled={!this.props.enableSave}
            onClick={this.props.onSave}
          >

            Salvar

          </button>

          <button
            className="work-presence-data__cancel-button"
            onClick={this.props.onClickCancelPath}
          >

            Cancelar

          </button>

        </div>

      </DefaultSection>
    );
  }
}

export default WorkPresenceData;
