import React from 'react';
import { Link } from 'react-router-dom';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import ModelTable, {Property} from '../../utils/model_table';
import ConfirmationWindow from '../confirmation_window';
import DefaultInput, {HalfWrapper} from '../../utils/default_input';
import DefaultSection, {HorizontalRule, DefaultSubSectionTitle} from '../../utils/default_section';
import {getModels, deleteModel, setUrlParameters, getAsLocalDatetime, getLocalDateIsoString} from '../../utils/functions';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE} from '../../constants';
import * as permissions from '../../permissions';
import './work_presence_event_list.scss';

class WorkPresenceEventList extends React.Component {
  constructor(props) {
    super(props);

    let queryParameters = (new URLSearchParams(props.location.search));

    let initialDate = queryParameters.get('initial_date');
    let finalDate = queryParameters.get('final_date');

    if(!initialDate) {
      initialDate = new Date();
      initialDate.setDate(1);
      initialDate.setMonth(initialDate.getMonth() - 1);
      initialDate = getLocalDateIsoString(initialDate);
    }

    if(!finalDate) {
      finalDate = new Date();
      finalDate.setDate(0);
      finalDate = getLocalDateIsoString(finalDate);
    }

    this.state = {
      loadingData: true,
      work_presence_events: [],
      users: [],
      training_times: [],
      class_duration_map: {},
      deleteId: null,
      deleteInProgress: false,
      deleteFailed: false,
      deleteFailDescription: "",
      initialDate,
      initialDateInput: initialDate,
      finalDate,
      finalDateInput: finalDate,
      screenWidth: window.innerWidth
    };
  }

  async getWorkPresenceEventList() {
    const parameters = {load_username: true};

    if (this.state.initialDate) {
      parameters.initial_reference_date = this.state.initialDate;
    }
    if (this.state.finalDate) {
      parameters.final_reference_date = this.state.finalDate;
    }

    return await getModels(setUrlParameters(routes.WORK_PRESENCE_EVENTS_GET_API, parameters));
  }

  async componentDidMount() {
    let update = this.reloadList(false);

    update = await update;

    this.setState(update)

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.initialDate !== this.state.initialDate ||
        prevState.finalDate !== this.state.finalDate) {
      this.reloadList();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async reloadList(updateState=true) {
    if (updateState) {
      this.setState({
        loadingData: true
      });
    }

    const work_presence_events = await this.getWorkPresenceEventList();

    const update = {
      loadingData: false,
      work_presence_events
    };

    if (updateState) {
      this.setState(update);
    }

    return update;
  }

  onDeleteEntry(entryId) {
    this.setState({
      deleteId: entryId,
      deleteInProgress: false,
      deleteFailed: false
    });
  }

  onCancelDelete() {
    this.setState({
      deleteId: null
    });
  }

  async onConfirmDelete() {
    this.setState({
      deleteInProgress: true
    });

    try{
      if(await deleteModel(`${routes.WORK_PRESENCE_EVENT_DELETE_API}${this.state.deleteId}`)) {
        this.reloadList();
      }
    }
    catch(errors) {
      let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 209:
              errorDescription = 'Sessão do usuário expirada.';

              break;
            default:
          }
        }
      }

      this.setState({
        deleteFailDescription: errorDescription,
        deleteFailed: true,
        deleteInProgress: false
      });

      return;
    }

    this.setState({
      deleteId: null,
    });
  }

  listHasActions() {
    return this.props.userPermissionIds.includes(permissions.EDIT_WORK_PRESENCE_EVENT_PERMISSION_ID) || this.props.userPermissionIds.includes(permissions.DELETE_WORK_PRESENCE_EVENT_PERMISSION_ID);
  }

  getActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        {this.props.userPermissionIds.includes(permissions.EDIT_WORK_PRESENCE_EVENT_PERMISSION_ID) &&
          <Link
            className="model-table__default-edit-button"
            to={`${routes.WORK_PRESENCE_EVENT_EDIT_PATH}${entry.id}`}
          >

              <i className="fas fa-edit"></i>

          </Link>
        }

        {this.props.userPermissionIds.includes(permissions.DELETE_WORK_PRESENCE_EVENT_PERMISSION_ID) &&
          <button
            className="model-table__default-delete-button"
            onClick={() => this.onDeleteEntry(entry.id)}
          >

            <i className="far fa-trash-alt"></i>

          </button>
        }

      </div>
    );
  }

  getDateTimeText(isoDateTime) {
    if (!isoDateTime || isoDateTime.length <= 0) {
      return '';
    }

    const date = getAsLocalDatetime(isoDateTime, false);

    const dateFormat = {day: '2-digit', month: '2-digit', year: 'numeric'};

    let dateText = '';
    let hourText = isoDateTime.slice(11, 16);

    if(this.state.screenWidth > 515) {
      dateFormat.weekday = 'short';
    }

    dateText = new Intl.DateTimeFormat('pt-BR', dateFormat).format(date);

    return (
      <div className="work-presence-list__cell-wrapper">

        <p className="work-presence-list__date-text">

          {dateText}
          {' '}
          <span className="work-presence-list__time-text">{hourText}</span>

        </p>

      </div>
    );
  }

  getDateTimeFilter(isoDateTime) {
    if (!isoDateTime || isoDateTime.length <= 0) {
      return '';
    }

    const date = getAsLocalDatetime(isoDateTime, false);

    const dateFormat = {day: '2-digit', month: '2-digit', year: 'numeric'};

    let dateText = '';
    let hourText = isoDateTime.slice(11, 16);

    if(this.state.screenWidth > 515) {
      dateFormat.weekday = 'short';
    }

    dateText = new Intl.DateTimeFormat('pt-BR', dateFormat).format(date);

    return `${dateText} ${hourText}`;
  }

  getProperties() {
    let properties = [
      Property('user_id', 'Colaborador', <i className="fa-solid fa-user"></i>, {
        getDataText: (entry) => (
          <p className="work-presence-list__user-name">

            {entry.username}

          </p>
        ),
        getFilterText: (entry) => entry.username
      }),
      Property('entered_at', 'Entrada', <i className="fa-solid fa-clock"></i>, {
        getDataText: (entry) => this.getDateTimeText(entry.entered_at),
        getFilterText: (entry) => this.getDateTimeFilter(entry.entered_at)
      }),
      Property('exited_at', 'Saída', <i className="fa-solid fa-clock"></i>, {
        getDataText: (entry) => this.getDateTimeText(entry.exited_at),
        getFilterText: (entry) => this.getDateTimeFilter(entry.exited_at)
      }),
    ];

    return properties;
  }

  getConfirmationWindowTitle() {
    if(this.state.deleteInProgress) {
      return 'Deletando cadastro';
    }
    else if(this.state.deleteFailed) {
      return 'Falha ao deletar';
    }

    return 'Deletar cadastro';
  }

  getTimeText(value) {
    if (!value) {
      return '';
    }

    const hours = Math.floor(value);
    let seconds = Math.ceil(60 * 60 * (value % 1));
    const minutes = Math.floor(seconds / 60);
    seconds = seconds % 60;

    return `${hours}h ${minutes}m ${seconds}s`;
  }

  getWeekdayTableProperties() {
    let properties = [
      Property('staffName', 'Colaborador', <i className="fa-solid fa-user"></i>, {
        sortable: false
      }),
      Property('weekday_0', 'seg', null, {
        sortable: false,
        getDataText: (entry) => this.getTimeText(entry.weekday_0),
        getFilterText: (entry) => this.getTimeText(entry.weekday_0)
      }),
      Property('weekday_1', 'ter', null, {
        sortable: false,
        getDataText: (entry) => this.getTimeText(entry.weekday_1),
        getFilterText: (entry) => this.getTimeText(entry.weekday_1)
      }),
      Property('weekday_2', 'qua', null, {
        sortable: false,
        getDataText: (entry) => this.getTimeText(entry.weekday_2),
        getFilterText: (entry) => this.getTimeText(entry.weekday_2)
      }),
      Property('weekday_3', 'qui', null, {
        sortable: false,
        getDataText: (entry) => this.getTimeText(entry.weekday_3),
        getFilterText: (entry) => this.getTimeText(entry.weekday_3)
      }),
      Property('weekday_4', 'sex', null, {
        sortable: false,
        getDataText: (entry) => this.getTimeText(entry.weekday_4),
        getFilterText: (entry) => this.getTimeText(entry.weekday_4)
      }),
      Property('weekday_5', 'sáb', null, {
        sortable: false,
        getDataText: (entry) => this.getTimeText(entry.weekday_5),
        getFilterText: (entry) => this.getTimeText(entry.weekday_5)
      }),
      Property('weekday_6', 'dom', null, {
        sortable: false,
        getDataText: (entry) => this.getTimeText(entry.weekday_6),
        getFilterText: (entry) => this.getTimeText(entry.weekday_6)
      }),
      Property('totalHours', 'Total', null, {
        getSortCallback: (a, b) => {
          if (a.id === 0) {
            return -1;
          }

          return a.totalHours - b.totalHours;
        },
        sortable: false,
        getDataText: (entry) => this.getTimeText(entry.totalHours),
        getFilterText: (entry) => this.getTimeText(entry.totalHours)
      })
    ];

    return properties;
  }

  getPresenceOverview() {
    const staffMap = new Map();
    const totalWeekdayMap = new Map();
    let totalHours = 0;

    for (const work_presence_event of this.state.work_presence_events) {
      if (work_presence_event.entered_at.length <= 0 || work_presence_event.exited_at.length <= 0) {
        continue;
      }

      let staffMapEntry;

      if (!staffMap.has(work_presence_event.user_id)) {
        staffMapEntry = {
          user_id: work_presence_event.user_id,
          username: work_presence_event.username,
          weekdayMap: new Map(),
          totalHours: 0
        };

        staffMap.set(work_presence_event.user_id, staffMapEntry);
      }
      else {
        staffMapEntry = staffMap.get(work_presence_event.user_id);
      }

      const entered_at = getAsLocalDatetime(work_presence_event.entered_at, false);
      const exited_at = getAsLocalDatetime(work_presence_event.exited_at, false);
      const timeDiff = exited_at.getTime() - entered_at.getTime();

      let entryHours = timeDiff / (1000 * 3600);

      staffMapEntry.totalHours += entryHours;
      totalHours += entryHours;

      let totalWeekdayMapEntry = 0;

      let dayId = entered_at.getDay() - 1;

      if (dayId < 0) {
        dayId = 6;
      }

      if (totalWeekdayMap.has(dayId)) {
        totalWeekdayMapEntry = totalWeekdayMap.get(dayId);
      }

      totalWeekdayMapEntry += entryHours;
      totalWeekdayMap.set(dayId, totalWeekdayMapEntry);

      let weekdayMapEntry = 0;

      if (staffMapEntry.weekdayMap.has(dayId)) {
        weekdayMapEntry = staffMapEntry.weekdayMap.get(dayId);
      }

      weekdayMapEntry += entryHours;
      staffMapEntry.weekdayMap.set(dayId, weekdayMapEntry);
    }

    const weekdayTableData = [];

    for (const staffMapEntry of staffMap.values()) {
      weekdayTableData.push({
        id: staffMapEntry.user_id,
        staffName: staffMapEntry.username,
        weekday_0: staffMapEntry.weekdayMap.get(0),
        weekday_1: staffMapEntry.weekdayMap.get(1),
        weekday_2: staffMapEntry.weekdayMap.get(2),
        weekday_3: staffMapEntry.weekdayMap.get(3),
        weekday_4: staffMapEntry.weekdayMap.get(4),
        weekday_5: staffMapEntry.weekdayMap.get(5),
        weekday_6: staffMapEntry.weekdayMap.get(6),
        totalHours: staffMapEntry.totalHours,
      });
    }

    weekdayTableData.push({
      id: 0,
      staffName: 'Total',
      weekday_0: totalWeekdayMap.get(0),
      weekday_1: totalWeekdayMap.get(1),
      weekday_2: totalWeekdayMap.get(2),
      weekday_3: totalWeekdayMap.get(3),
      weekday_4: totalWeekdayMap.get(4),
      weekday_5: totalWeekdayMap.get(5),
      weekday_6: totalWeekdayMap.get(6),
      totalHours: totalHours,
    });

    return (
      <React.Fragment>

        <HorizontalRule />

        <DefaultSubSectionTitle
          icon={<i className="fa-solid fa-clock"></i>}
          text="Resumo do período"
        />

        <ModelTable
          id='training_time:weekday_table'
          properties={this.getWeekdayTableProperties()}
          getRowClassName={(row) => row.id === 0 ? 'work-presence-list__overview-table__total-row' : ''}
          data={weekdayTableData}
          initialLinesPerPage={weekdayTableData.length}
          initialOrderBy="totalHours"
          initialOrderIsDecrescent={true}
          hideFilter={true}
          hideLinesPerPageControl={true}
          hideNavigationControls={true}
        >
        </ModelTable>

      </React.Fragment>
    );
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  mayUpdateDateInputs() {
    if((this.state.initialDateInput !== this.state.initialDate && this.state.initialDateInput.length > 0) ||
        (this.state.finalDateInput !== this.state.finalDate && this.state.finalDateInput.length > 0)) {
      return true;
    }

    return false;
  }

  applyDateInputChanges() {
    if(this.mayUpdateDateInputs()) {
      this.props.history.replace(setUrlParameters(routes.WORK_PRESENCE_EVENT_LIST_PATH, {
        initial_date: this.state.initialDateInput,
        final_date: this.state.finalDateInput,
      }));

      this.setState({
        initialDate: this.state.initialDateInput,
        finalDate: this.state.finalDateInput,
      });
    }
  }

  render() {
    return (
      <React.Fragment>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.state.deleteFailed ? this.state.deleteFailDescription : 'Todos os dados relacionados ao cadastro serão removidos'}
          confirmText="Deletar cadastro"
          cancelText={this.state.deleteFailed ? 'Ok' : 'Cancelar'}
          visible={this.state.deleteId !== null}
          onCancel={() => this.onCancelDelete()}
          onConfirm={() => this.onConfirmDelete()}
          loading={this.state.deleteInProgress}
          useErrorIcon={this.state.deleteFailed}
          hideConfirmButton={this.state.deleteFailed}
        />

        <ContentFrame
          location={this.props.location}
          headerHistory={[
            {
              path: routes.DESKTOP_PATH,
              text: "Área de trabalho"
            },
            {
              path: routes.WORK_PRESENCE_EVENT_LIST_PATH,
              text: "Controle de ponto"
            },
          ]}
          titleIcon={<i className="fas fa-clipboard-list"></i>}
          title="Controle de ponto"
          loading={this.state.loadingData}
        >

          <DefaultSection
            className="work-presence-list"
            title="Controle de ponto"
          >

            <div className="work-presence-list__list-actions">

              {this.props.userPermissionIds.includes(permissions.ADD_WORK_PRESENCE_EVENT_PERMISSION_ID) &&
                <Link
                  className="model-table__default-button"
                  to={routes.WORK_PRESENCE_EVENT_ADD_PATH}
                >

                  <i className="fas fa-plus"></i> Adicionar entrada

                </Link>
              }

            </div>

            <div className="work-presence-list__filters">

              <header className="work-presence-list__filters__header">

                <h4 className="work-presence-list__filters__header__text">Filtros</h4>

              </header>

              <div className="work-presence-list__filters__inputs">

                <div className="work-presence-list__filters__inputs-wrapper">

                  <HalfWrapper>

                    <DefaultInput
                      name="initialDateInput"
                      label="Data inicial"
                      type="date"
                      placeholder="Data inicial"
                      max={this.state.finalDateInput}
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.initialDateInput}
                    />

                    <DefaultInput
                      name="finalDateInput"
                      label="Data final"
                      type="date"
                      placeholder="Data final"
                      min={this.state.initialDateInput}
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.finalDateInput}
                    />

                  </HalfWrapper>

                </div>

                {this.mayUpdateDateInputs() &&
                  <button
                    className="work-presence-list__filters__refresh-button"
                    onClick={() => this.applyDateInputChanges()}
                  >

                    <i className="fas fa-sync"></i>

                  </button>
                }

              </div>

            </div>

            <HorizontalRule />

            <ModelTable
              properties={this.getProperties()}
              getActions={this.listHasActions() ? (entry) => this.getActions(entry) : null}
              data={this.state.work_presence_events}
              initialOrderBy="entered_at"
              initialOrderIsDecrescent={true}
            >

            </ModelTable>

            {this.getPresenceOverview()}

          </DefaultSection>

        </ContentFrame>

      </React.Fragment>
    );
  }
}

export default WorkPresenceEventList;
